<template>
  <div class="convert-key-edit">
    <menu-item label="背景设置">
      <menu-fill :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="PC端背景图">
      <menu-background-image :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="移动端背景图">
      <menu-background-image :target="model.mobileBackground" target-key="mobileBackground" />
    </menu-item>
    <menu-item label="布局">
      <menu-switch v-model="model.titleVisible" label="标题" target-key="titleVisible" />
      <menu-switch v-model="model.subtitleVisible" label="描述" target-key="subtitleVisible" />
    </menu-item>
    <menu-item label="兑换框圆角">
      <menu-slider v-model="model.radius" target-key="radius" />
    </menu-item>
    <menu-item label="兑换框背景色">
      <menu-fill :target="model.inputBackground" target-key="inputBackground" />
    </menu-item>
    <menu-item label="兑换框描边色">
      <menu-fill :target="model.borderBackground" target-key="borderBackground" />
    </menu-item>
    <menu-item label="按钮背景颜色">
      <menu-fill :target="model.buttonBackground" target-key="buttonBackground" />
    </menu-item>
    <menu-item label="按钮文字颜色">
      <menu-fill :target="model.buttonTextColor" solid-color target-key="buttonTextColor" />
    </menu-item>
    <menu-item label="输入框文字颜色">
      <menu-fill :target="model.inputTextColor" solid-color target-key="inputTextColor" />
    </menu-item>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import MenuFill from "~/components/menu/menuFill.vue"
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue"
import MenuItem from "~/components/menu/menuItem.vue"
import MenuSwitch from "~/components/menu/menuSwitch.vue"
import RemoveBtn from "~/components/common/RemoveBtn.vue"
import MenuSlider from "~/components/menu/menuSlider.vue";

export default {
  name: "ConvertKeyEdit",
  components: {MenuSlider, RemoveBtn, MenuSwitch, MenuItem, MenuBackgroundImage, MenuFill},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="less">

</style>
