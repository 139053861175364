<template>
  <bg-style class="recharge-widget-v2" :class="{ 'is-mobile': $store.getters.isMobile }" :bg="model.background" :style="getStyle">
    <div class="content relative">
      <div class="recharge-widget-v2__title">
        <rich-text v-if="model.titleVisible" v-model="model.title"  :disabled="!editing" :editing="editing" :exclude="['font-size']" :class="$store.getters.isDesktop ? 'text-[40px]' : 'text-[22px]'" />
      </div>
      <div class="recharge-widget-v2__desc">
        <div v-if="model.descVisible" :class="[$store.getters.isMobile ? 'text-[12px]' : 'text-[18px]']">
          <rich-text v-model="model.describe" :editing="editing" :exclude="['font-size']" :disabled="!editing" theme="snow" />
        </div>
      </div>
      <div class="recharge-widget-v2__list">
        <template v-for="(item, index) in list">
          <bg-style v-if="item.type === 2" :key="'any' + index" class="item" :bg="model.cardBackground" @click.native="handleAnyItemClick(item)">
            <div class="thumb flex items-center justify-center">
              <img class="relative" :src="item.icon" alt="">
            </div>
            <div class="relative">
              <div class="price relative">
                <currency-input v-model="inputValue" class="any-input" :class="{ 'is-mobile': $store.getters.isMobile }" currency="$" @click.native.stop />
              </div>
              <div v-if="activityRule.percent" class="extra relative">
                <span class="mr-1">Bonus:</span>
                <img class="w-[20px]" :src="config.icon" alt="">
                <i class="el-icon-close font-bold" />
                <span>{{ Number(inputValue * (activityRule.percent / 100)).toFixed(2) }}</span>
              </div>
              <div class="handle">
                <div class="btn relative">{{ currencyLabel }} {{ inputValue || 0 }} {{ currency }}</div>
              </div>
            </div>
          </bg-style>
          <bg-style v-else :key="'child' + index" class="item" :bg="model.cardBackground" @click.native="handleItemClick(item)">
            <div class="thumb flex items-center justify-center">
              <img class="relative" :src="item.icon" alt="">
            </div>
            <div class="relative">
              <div class="price relative">
                <img :src="config.icon" alt="">
                <i class="el-icon-close font-bold mx-1" />
                <span>{{ item.value }}</span>
              </div>
              <div v-if="activityRule.percent" class="extra relative">
                <span class="mr-1">Bonus:</span>
                <img class="w-[20px]" :src="config.icon" alt="">
                <i class="el-icon-close font-bold" />
                <span>{{ item.giftValue }}</span>
              </div>
              <div class="handle">
                <div class="btn relative">{{ item.priceText }}</div>
              </div>
            </div>
          </bg-style>
        </template>
      </div>
    </div>
    <desk-pay-modal v-if="deskPay.visible" :desk-pay="deskPay" :lang-id-data="$store.state.locale.langIdData" :store-lang="storeLang" @close="closePayModal" />
  </bg-style>
</template>

<script>
import { Message } from 'element-ui'
import cloneDeep from "lodash.clonedeep";
import RichText from "~/components/richText/index.vue";
import tools, {checkIgnoreMessage, checkUser, generateOrderParams, generatePayUrl, getTextColor, sleep} from "~/utils";
import CurrencyInput from "~/site/components/currencyInput.vue";
import {DefaultImage} from "~/enums/defaultImageEnum";
import {BusEventMap, TypeData} from "~/enums/data";
import DeskPayModal from "~/components/pay/deskPayModal.vue";
import Bus from "~/site/model/bus";
export default {
  name: 'RechargeWidgetV2',
  components: {
    DeskPayModal,
    CurrencyInput,
    RichText,
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      list: [],
      activityRule: {},
      inputValue: null,
      orderLoading: false,
      config: {
        icon: ''
      },
      storeLang: null,
      deskPay: {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      }
    }
  },
  computed: {
    DefaultImage() {
      return DefaultImage
    },
    getStyle() {
      return {
        '--button-bg-color': getTextColor(this.model.buttonBackground)?.color,
        '--button-text-color': getTextColor(this.model.buttonColor)?.color,
        '--card-border-radius': this.model.cardRadius + 'px',
        '--text-color': getTextColor(this.model.textColor)?.color
      }
    },
    awaitGoods() {
      return this.$store.state.site.awaitGoods
    },
    ipInfo() {
      return this.$store.state.user.ipInfo
    },
    currencyLabel() {
      return this.$store.state.project.currencieMap[this.ipInfo.currency]?.label
    },
    currency() {
      return this.ipInfo.currency
    }
  },
  created() {
    this.init()
  },
  mounted() {
    Bus.$on('reloadGood', (type) => {
      const isLogin = type === 'login'
      if (isLogin) {
        this.checkBuy()
      }
    })
  },
  methods: {
    checkBuy() {
      const isAim = this.awaitGoods && (this.awaitGoods.modelId === this.model.id)
      if (isAim) this.createOrder(this.awaitGoods.data)
    },
    closePayModal() {
      this.$store.commit('site/SET_SHOP_GOOD', null)
      Bus.$emit('reloadActivity', BusEventMap.Payment)
      this.deskPay = {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      }
    },
    init() {
      this.storeLang = tools.getStoreLang(this)
      this.queryList()
    },
    async createOrder(record) {
      this.orderLoading = true
      const [err, res] = await this.$utils.to(generateOrderParams(this, record || this.currentGoods, TypeData.recharge))
      this.orderLoading = false
      await sleep(500)
      if (!err) {
        this.$store.commit('site/SET_AWAIT_GOODS', null)
        generatePayUrl(res, this)
      } else {
        !checkIgnoreMessage(err) && Message.error(err)
      }
    },
    handleAnyItemClick(item) {
      if (!this.inputValue) return
      const params = {
        value: Number(this.inputValue),
        rechargeId: this.config.id,
        id: item.id
      }
      this.handleItemClick(params)
    },
    async handleItemClick(record) {
      if (this.orderLoading) return
      this.currentGoods = record
      await checkUser(this, () => {
        this.$store.commit('site/SET_AWAIT_GOODS', { modelId: this.model.id, data: cloneDeep(this.currentGoods) })
      })
      await this.createOrder(record)
    },
    async queryList() {
      const params = {
        merchant_id: this.$store.state.project.merchantId,
        project_id: this.$store.state.project.projectId,
        currency: this.ipInfo.currency
      }
      const [err, res] = await this.$utils.to(this.$api.recharge.getRechargeList(params))
      if (!err) {
        this.activityRule = JSON.parse(res?.rebate_activity?.activity_rule || '{}')
        this.config = res.recharge_conf
        this.list = (res.data || []).map(item => {
          const newItem = {...item}
          if (this.activityRule.percent) {
            newItem.giftValue = Number(item.value * (this.activityRule.percent / 100)).toFixed(2)
          }
          newItem.rechargeId = this.config.id
          newItem.priceText = `${this.currencyLabel} ${item.value} ${this.ipInfo.currency}`
          return newItem
        })
      } else {
        Message.error(err)
      }
    }
  }
}
</script>

<style lang="less">
.recharge-widget-v2 {
  padding-bottom: 40px;
  .content {
    max-width: 960px;
    margin: 0 auto;
  }
  &__title {
    padding: 60px 0 20px 0;
  }
  &__desc {
    padding-bottom: 40px;
  }
  &__list {
    display: flex;
    gap: 30px;
    padding: 0 46px;
    flex-wrap: wrap;
    .item {
      width: 194px;
      height: 294px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: var(--text-color);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      cursor: pointer;
      border-radius: var(--card-border-radius);
      .color-bg {
        border-radius: var(--card-border-radius);
      }
      .thumb {
        height: 120px;
        margin-bottom: 20px;
        margin-top: 40px;
        img {
          max-height: 100%;
        }
      }
      .price {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        .currency-input {
          height: 32px;
          border-radius: var(--card-border-radius);
          &__label {
            background-color: var(--button-text-color);
            color: var(--button-bg-color);
            height: 32px;
            line-height: 32px;
          }
          &__inner {
            width: 120px;
            height: 32px;
            background-color: var(--button-text-color);
            color: var(--button-bg-color);
            border-radius: 0;
          }
        }
        img {
          width: 30px;
        }
      }
      .extra {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin-top: 10px;
        font-weight: bold;
        .el-icon-close {
          margin: 0 4px;
        }
      }
      .handle {
        padding: 0 12px 12px 12px;
      }
      .btn {
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--button-bg-color);
        color: var(--button-text-color);
        border-radius: var(--card-border-radius);
        font-size: 18px;
        margin-top: 12px;
        font-weight: bold;
        white-space: nowrap;
      }
    }
  }
  &.is-mobile {
    .recharge-widget-v2__title {
      padding: 40px 0 20px 0;
    }
    .recharge-widget-v2__desc {
      padding-bottom: 20px;
    }
    .recharge-widget-v2__list {
      padding: 0 12px;
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(3, 1fr);
      .item {
        width: 100%;
        height: fit-content;
        .thumb {
          height: 60px;
          margin-top: 30px;
        }
        .price {
          font-size: 14px;
          .currency-input {
            margin: 0 8px;
            height: 24px;
            &__inner {
              width: 100%;
              height: 24px;
            }
            &__label {
              height: 24px;
              line-height: 24px;
            }
          }

          img {
            width: 24px;
          }
        }
        .extra {
          font-size: 10px;
          margin-top: 8px;
          img {
            width: 16px;
          }
          .el-icon-close {
            margin: 0;
          }
        }
        .handle {
          padding: 0 8px 8px 8px;
        }
        .btn {
          font-size: 10px;
          height: 24px;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
