<template>
  <bg-style :bg="model.goodsBackground" class="store-widget-v2__pc-list-item" @click.native="handleClick">
    <im-image v-if="isHasCorner" :src="good.corner" class="corner-logo" />
    <div v-if="stopCountdownTimer" class="stop-countdown">
      <i class="el-icon-time mr-1" />
      <span>{{ $t('siteBuild.stopCountdown') }}：</span>
      <span>{{ stopCountdownDays }}d:{{ stopCountdownHours }}:{{ stopCountdownMinutes }}:{{ stopCountdownSeconds }}</span>
    </div>
    <bg-style class="thumb relative" :bg="{...good.thumbBg, backgroundSize: model.iconFillType}" />
    <div class="name relative">{{ good.names.name }}</div>
    <div v-if="good.names.desc" class="desc relative" :title="good.names.desc">{{ good.names.desc }}</div>
    <div v-if="isShowOriginalPrice || good.is_purchase_limit" class="info flex justify-between relative">
      <div class="original-price">
        <span v-if="isShowOriginalPrice">{{ originalPriceText }}</span>
      </div>
      <div v-if="good.is_purchase_limit" class="limit">Limit {{ good.has_purchase_num }}/{{ good.limit_purchase_num }}</div>
    </div>
    <div v-if="hasGift" class="gifts flex items-center relative">
      <span class="mr-2 flex-shrink-0">Gifts：</span>
      <div class="flex items-center hide-scroll overflow-auto w-full">
        <div v-for="(item, index) in good.gift_list" :key="index + 'gift'" class="gift-item ml-1 first-of-type:ml-0 mr-1 flex items-center flex-shrink-0">
          <img class="w-[16px] h-[16px]" alt="" :src="item.gift_goods_logo">
          <i class="el-icon-close mx-1" />
          <span>{{ item.num }}</span>
        </div>
      </div>
    </div>
    <div v-if="isShowCountdown" class="pre-btn relative">
      <div class="flex items-center">
        <i class="el-icon-time text-14 mr-1" />
        <template v-if="countdownDays === '00' && countdownHours !== '00'">
          <span>{{ countdownHours }}h:{{ countdownMinutes }}m</span>
        </template>
        <template v-if="countdownDays !== '00'">
          <span>{{ countdownDays }}d:{{ countdownHours }}h</span>
        </template>
        <template v-if="countdownDays === '00' && countdownHours === '00'">
          <span>{{ countdownMinutes }}m:{{ countdownSeconds }}s</span>
        </template>
      </div>
      <span class="text-14 flex-shrink-0">{{ realPriceText }}</span>
    </div>
    <div v-else class="pay-btn relative">
      <shop-cart v-if="hasShopCart" class="shop-cart" :site="site" :good="good" :disabled="editing" />
      <div class="price-block" :class="{ 'no-shop-cart': !hasShopCart }" @click.stop="handleSubmit">{{ realPriceText }}</div>
    </div>
  </bg-style>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import ShopCart from '../shopCart.vue'
import goodMixins from "~/site/widgets/storeWidget/oneGood/goodMixins";

export default {
  name: "PcListItem",
  components: {ShopCart},
  mixins: [goodMixins],
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    handleClick() {
      if (this.editing) return
      this.$emit('click', this.good)
    },
    handleSubmit() {
      this.$emit('submit', cloneDeep(this.good))
    }
  }
}
</script>

<style lang="less">
.store-widget-v2__pc-list-item {
  width: 294px;
  height: fit-content;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  cursor: pointer;
  border-radius: var(--goods-border-radius);
  .stop-countdown {
    position: absolute;
    top: 150px;
    right: 16px;
    background-color: var(--button-bg-color);
    color: var(--button-text-color);
    padding: 4px 10px;
    font-size: 14px;
    border-radius: 25px;
    z-index: 10;
  }
  .color-bg {
    border-radius: var(--goods-border-radius) var(--goods-border-radius);
  }
  .hide-scroll {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .corner-logo {
    position: absolute;
    left: -4px;
    top: -5px;
    width: 97px;
    height: 97px;
    z-index: 10;
  }
  .thumb {
    width: 100%;
    height: 192px;
    border-radius: var(--goods-border-radius) var(--goods-border-radius) 0 0;
    overflow: hidden;
  }
  .name {
    font-size: 18px;
    margin-top: 16px;
    padding: 0 16px;
    color: var(--main-text-color);
  }
  .desc {
    font-size: 14px;
    margin-top: 8px;
    padding: 0 16px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color: var(--second-text-color);
  }
  .info {
    margin-top: 12px;
    padding: 0 16px;
    font-size: 14px;
    .original-price {
      color: var(--second-text-color);
      text-decoration: line-through;
    }
    .limit {
      color: var(--main-text-color);
      border: 1px solid var(--main-text-color);
      padding: 0 4px;
      border-radius: var(--goods-border-radius);
    }
  }
  .gifts {
    font-size: 14px;
    margin-top: 16px;
    padding: 0 16px;
    color: var(--second-text-color);
  }
  .pre-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    background-color: rgba(0, 0, 0, 0.3);
    margin: 20px 16px 16px 16px;
    color: white;
    padding: 0 8px;
    font-size: 12px;
    border-radius: var(--goods-border-radius);
  }
  .pay-btn {
    padding: 0 16px;
    margin-top: 20px;
    margin-bottom: 16px;
    display: flex;
    .shop-cart {
      width: 32px;
      height: 32px;
      background: var(--button-bg-color);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--button-text-color);
      font-size: 18px;
      border-radius: var(--goods-border-radius) 0 0 var(--goods-border-radius);
      flex-shrink: 0;
      margin-right: 5px;
    }
    .price-block {
      background: var(--button-bg-color);
      width: 100%;
      line-height: 32px;
      text-align: center;
      border-radius: 0 var(--goods-border-radius) var(--goods-border-radius) 0;
      color: var(--button-text-color);
      &.no-shop-cart {
        border-radius: var(--goods-border-radius);
      }
    }
  }
}
</style>
