<template>
  <div class="sign-activity-edit">
    <menu-item label="背景设置">
      <menu-fill :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="PC端背景图">
      <menu-background-image :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="移动端背景图">
      <menu-background-image :target="model.mobileBackground" target-key="mobileBackground" />
    </menu-item>
    <menu-item label="布局">
      <menu-switch v-model="model.titleVisible" target-key="titleVisible" label="标题" />
      <menu-switch v-model="model.subtitleVisible" target-key="subtitleVisible" label="描述" />
    </menu-item>
    <menu-item label="文字颜色">
      <menu-fill class="mt-2" :target="model.textColor" solid-color target-key="textColor" />
    </menu-item>
    <menu-item label="按钮文字颜色">
      <menu-fill :target="model.buttonColor" solid-color target-key="buttonColor" />
    </menu-item>
    <menu-item label="奖品卡片背景">
      <menu-background-image :target="model.signBackground" target-key="signBackground" />
      <menu-fill class="mt-2" :target="model.signBackground" target-key="signBackground" />
    </menu-item>
    <menu-item label="奖品Logo背景">
      <menu-background-image :target="model.logoBackground" target-key="logoBackground" />
      <menu-fill class="mt-2" :target="model.logoBackground" target-key="logoBackground" />
    </menu-item>
    <menu-item label="不可签按钮">
      <menu-background-image :target="model.doneBackground" target-key="doneBackground" />
      <menu-fill class="mt-2" :target="model.doneBackground" target-key="doneBackground" />
    </menu-item>
    <menu-item label="补签按钮">
      <menu-background-image :target="model.repairBackground" target-key="repairBackground" />
      <menu-fill class="mt-2" :target="model.repairBackground" target-key="repairBackground" />
    </menu-item>
    <menu-item label="可签到/领取按钮">
      <menu-background-image :target="model.buttonBackground" target-key="buttonBackground" />
      <menu-fill class="mt-2" :target="model.buttonBackground" target-key="buttonBackground" />
    </menu-item>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import RemoveBtn from "~/components/common/RemoveBtn.vue";
import MenuItem from "~/components/menu/menuItem.vue";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue"
import MenuSwitch from "~/components/menu/menuSwitch.vue";
import MenuFill from "~/components/menu/menuFill.vue";

export default {
  name: 'SignActivityWidgetEdit',
  components: {MenuFill, MenuSwitch, MenuBackgroundImage, MenuItem, RemoveBtn},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {

  }
}
</script>

<style scoped lang="less">

</style>
