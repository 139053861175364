<template>
  <div class="single-goods__submenu-item">
    <menu-title :close="close">商品{{ childIndex + 1 }}</menu-title>
    <menu-item label="选择商品">
      <im-select v-model="target.goodsId" class="w-full" type="goods" size="small" :options="$store.state.goods.goodsList" />
    </menu-item>
    <menu-item label="商品卡片背景">
      <menu-fill :target="target.background" />
      <menu-background-image class="mt-2" :target="target.background" />
    </menu-item>
    <menu-item label="商品LOGO背景">
      <menu-fill :target="target.logoBackground" />
      <menu-background-image class="mt-2" :target="target.logoBackground" />
    </menu-item>
    <menu-item label="按钮背景" size-tip="(278*60)">
      <menu-fill :target="target.buttonBackground" />
      <menu-background-image class="mt-2" :target="target.buttonBackground" />
    </menu-item>
    <menu-item label="名称/描述颜色">
      <menu-fill :target="target.nameBackground" solid-color target-key="nameBackground" />
    </menu-item>
    <menu-item label="价格颜色">
      <menu-fill :target="target.priceBackground" solid-color target-key="priceBackground" />
    </menu-item>
    <menu-item label="商品详情页背景">
      <menu-fill :target="target.infoBackground" target-key="infoBackground" />
    </menu-item>
    <delete-block-trigger class="mt-4" title="删除商品" :is-delete-block="false" @delete-block="onDelete" />
  </div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem.vue";
import menuTitle from "~/components/menu/menuTitle.vue";
import MenuFill from "~/components/menu/menuFill.vue";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue";
import ImSelect from "~/components/common/ImSelect.vue";
import deleteBlockTrigger from "~/site/components/editMenu/deleteBlockTrigger.vue";

export default {
  name: "SingleGoodsWidgetGoodsEdit",
  components: {deleteBlockTrigger, ImSelect, MenuBackgroundImage, MenuFill, menuTitle, MenuItem},
  props: {
    childIndex: {
      type: [Number, undefined],
      default() {
        return undefined
      },
    },
    model: {
      type: [Object, undefined],
      default() {
        return undefined
      },
    },
    target: {
      type: Object,
      default() {
        return {}
      },
    },
    editing: {
      type: Boolean,
      default: false,
    },
    site: {
      type: Object,
      default() {
        return {}
      },
    }
  },
  data() {
    return {}
  },
  methods: {
    close() {
      this.SiteMenu.close()
    },
    onDelete() {
      this.close()
      const index = this.model.children.findIndex(child => child.id === this.target.id)
      if (index > -1) {
        this.model.children.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="less">

</style>
