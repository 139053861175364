<template>
  <bg-style class="recharge-widget py-10 overflow-x-auto" :class="{ 'is-mobile': device === DeviceEnum.MOBILE }" :style="getStyle" :mobile-bg="model.mobileBackground" :bg="model.background">
    <div class="recharge-widget__content relative">
      <activity v-if="currency.currency" ref="activity" :model="model" :editing="editing" :class="{ 'is-mobile': device === DeviceEnum.MOBILE }" :currency-label="currencyLabel" :currency-code="currency.currency" />
      <div class="recharge-widget__content__list">
        <bg-style v-for="(item, index) in model.list" :key="index" :bg="item.background" :radius="model.radius" class="item" :style="getTextColor(item.faceColor, item.faceColorShow)" @click.native="handlePay(item)">
          <template v-if="item.faceId !== RechargeAnyValueId">
            <div class="price flex justify-center w-full">{{ getPrice(item.faceId, index)?.priceTxt }}</div>
            <div class="number">
              <img :src="getIcon(item.faceId)" alt="">
              <i class="el-icon-close text mx-1 font-bold" />
              <span>{{ getPrice(item.faceId, index)?.price }}</span>
            </div>
            <div v-if="getGoodsId(item.faceId).item_id === $store.state.project.rechargeActivity.goods_id && getFreePrice(item.faceId) > 0" class="free">
              <span class="mr-1">{{ $t('siteBuild.gift') }}:</span>
              <img class="icon" :src="getIcon(item.faceId)" alt>
              <i class="el-icon-close text font-bold" />
              <span class="text">{{ getFreePrice(item.faceId) }}</span>
            </div>
          </template>
          <template v-else>
            <div class="title text-white">{{ $t('siteBuild.activity.rechargeTip1') }}</div>
            <div class="w-full items-center flex justify-center" @click.stop>
              <currency-input v-model="anyValue" :currency="currencyLabel" class="any-input" :class="{ 'is-mobile': device === DeviceEnum.MOBILE }" @click.native.stop />
            </div>
            <div v-if="anyValue" class="number">
              <img :src="getIcon(model.list[0].faceId)" alt="">
              <i class="el-icon-close text mx-1 font-bold" />
              <span class="font-bold">{{ anyValue }}</span>
            </div>
            <div v-if="anyValueFree > 0" class="free">
              <span class="mr-1">{{ $t('siteBuild.gift') }}:</span>
              <img class="icon" :src="getIcon(model.list[0].faceId)" alt>
              <i class="el-icon-close text font-bold" />
              <span class="text">{{ anyValueFree }}</span>
<!--              <div class="right-text">{{ $t('siteBuild.free') }} </div>-->
            </div>
          </template>
        </bg-style>
      </div>
    </div>
    <desk-pay-modal v-if="deskPay.visible" :desk-pay="deskPay" :lang-id-data="$store.state.locale.langIdData" @close="closePayModal" />
  </bg-style>
</template>

<script>
import {mapState} from 'vuex'
import {Message} from "element-ui"
import cloneDeep from "lodash.clonedeep"
import Activity from './activity.vue'
import Bus from '~/site/model/bus'
import CurrencyInput from "~/site/components/currencyInput.vue"
import tools, {formatSpePrice, getTextColor, checkUser, checkIgnoreMessage} from "~/utils"
import { RechargeAnyValueId } from '~/enums/siteEnum'
import {DeviceEnum} from "~/enums/deviceEnum"
import DeskPayModal from "~/components/pay/deskPayModal.vue"
import {isMobile} from "~/utils/types"
import {isBuildWebSite, PAY_URL} from "~/config"
import {DefaultImage} from "~/enums/defaultImageEnum"

const isSandBox = !isBuildWebSite
export default {
  name: 'RechargeWidget',
  components: {
    DeskPayModal,
    Activity,
    CurrencyInput
  },
  props: {
    device: {
      type: String,
      default: ''
    },
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      RechargeAnyValueId,
      getTextColor,
      anyValue: '',
      deskPay: {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      }
    }
  },
  computed: {
    anyValueFree() {
      let value = ((Number(this.anyValue) * this.$store.state.project.rechargeActivity.percent)).toFixed(2)
      const overValue = this.$store.state.project.rechargeStatic.quota - this.$store.state.project.rechargeStatic.used
      if (value > overValue) {
        value = formatSpePrice(overValue)
      } else if (overValue <= 0) {
        value = 0
      }
      return value
    },
    DeviceEnum() {
      return DeviceEnum
    },
    currency() {
      return tools.getLangByip(this)
    },
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo
    }),
    currencyLabel() {
      return this.$store.state.project.currencieMap[this.currency.currency]?.label
    },
    awaitGoods() {
      return this.$store.state.site.awaitGoods
    },
    getStyle() {
      return {
        '--recharge-column': this.model.column,
        '--recharge-mobile-column': this.model.mobileColumn
      }
    }
  },
  mounted() {
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      this.init()
      Bus.$on('reloadGood', (type) => {
        const isLogin = type === 'login'
        if (isLogin) {
          this.checkBuy()
        }
      })
    }
  },
  methods: {
    checkBuy() {
      const isAim = this.awaitGoods && (this.awaitGoods.modelId === this.model.id)
      isAim && this.createOrder(this.awaitGoods.data)
    },
    closePayModal() {
      this.deskPay = {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      }
      Bus.$emit('reloadActivity', 'payment')
    },
    async handlePay(item) {
      await checkUser(this, () => {
        this.$store.commit('site/SET_AWAIT_GOODS', { modelId: this.model.id, data: cloneDeep(item) })
      })
      await this.createOrder(item)
    },
    getFreePrice(id) {
      const price = this.getPrice(id)?.price || 0
      let result
      const value = ((Number(price) * this.$store.state.project.rechargeActivity.percent)).toFixed(2)
      const overValue = this.$store.state.project.rechargeStatic.quota - this.$store.state.project.rechargeStatic.used
      if (!this.userInfo?.uid) {
        result = value
      } else if (Number(value) > overValue) {
        result = formatSpePrice(overValue)
      } else if (overValue <= 0) {
        result = 0
      } else {
        result = value
      }
      return result
    },
    async createOrder(item) {
      const return_url = `${window.location.protocol}//${window.location.host}${window.location.pathname}?from=${this.$route.query?.from}`
      const isPreviewMode = !isBuildWebSite
      const isAnyFace = item.faceId === RechargeAnyValueId
      const faceId = Number(isAnyFace ? 0 : item.faceId)
      const priceSetting = this.$store.getters.rechargeValuePriceSettingMap[faceId]
      const {
        project_id,
        merchant_id
      } = this.userInfo
      const ipInfo = tools.getxfip(localStorage)
      const priceInfo = tools.getPriceInfo(priceSetting, this.currency, this)
      const price = isAnyFace ? parseFloat(this.anyValue) : +priceInfo.price
      const device = isMobile() ? 'mobile' : 'desktop'
      const { currency } = this.currency
      const lang = tools.getNeedParamsFormRouteParams(this.$route.params)?.lang || 'en'
      const params = {
        project_id,
        source: 2,
        merchant_id,
        currency,
        amount: price,
        from_preview_mode: isPreviewMode,
        items: [
          {
            item_id: faceId,
            num: 1,
            amount: price,
            price,
            currency,
            virtual_currency: currency,
            virtual_currency_amount: price,
          },
        ],
        settings: {
          view_source: this.$route.query.from,
          device,
          language: lang,
          show_order: false,
          return_url,
        },
      }
      const [err, res] = await this.$utils.to(this.$api.order.createOrder(params, ipInfo))
      this.$store.commit('site/SET_AWAIT_GOODS', null)
      if (!err) {
        this.openPayModal(res)
      } else {
        !checkIgnoreMessage(err) && Message.error(err)
      }
    },
    openPayModal(res) {
      const sandbox = isSandBox ? 1 : 0
      const { token, code } = res
      let payUrl = ''
      const env = process.env.RUN_ENV
      const isGoPay = token.slice(0, 2) === 'v2'
      if (isGoPay) {
        payUrl = `${PAY_URL[env]}/new-kop/payelement?access_token=${token}&sandbox=${+sandbox}`
      } else {
        payUrl = `${PAY_URL[env]}/pay/payelement?access_token=${token}&sandbox=${+sandbox}`
      }
      if (this.device === DeviceEnum.MOBILE) {
        this.$store.commit('site/SHOW_ORDER_MODAL', { code, payUrl })
      } else {
        this.deskPay = {
          visible: true,
          isGoPay,
          payUrl,
          orderCode: code
        }
      }
    },
    getPrice(id, index) {
      const priceSetting = this.$store.getters.rechargeValuePriceSettingMap[Number(id)]
      const editorMockPrice = { priceTxt: `${this.currencyLabel} ${(index + 1) * 100} ${this.currency.currency}`, price: (index + 1) * 100 }
      if (!this.editing && !priceSetting) return
      return priceSetting ? tools.getPriceInfo(priceSetting, this.currency, this) : editorMockPrice
    },
    getGoodsId(id) {
      return this.$store.getters.rechargeValueMap[Number(id)] || {}
    },
    getIcon(id) {
      const icon = this.$store.getters.rechargeValueIconMap[Number(id)]
      if (this.editing && !icon) {
        return DefaultImage.store
      } else {
        return icon
      }
    },
    async init() {
      await this.$store.dispatch('project/getCurrencyList')
      await this.$store.dispatch('project/getRechargeValues')
    }
  }
}
</script>

<style scoped lang="less">
.recharge-widget {
  &__content {
    width: 1150px;
    margin: 0 auto;
    &__list {
      display: grid;
      grid-template-columns: repeat(var(--recharge-column), 1fr);
      gap: 30px;
      .item {
        height: 310px;
        cursor: pointer;
        .title {
          width: 100%;
          text-align: center;
          font-size: 19px;
          font-weight: 500;
          margin-top: 14px;
          position: relative;
        }
        .any-input {
          margin: 0 auto;
        }
        .price {
          position: absolute;
          top: 14px;
          left: 50%;
          transform: translate(-50%, 0);
          font-size: 24px;
          line-height: 38px;
          text-shadow: 0.58778524px 0.809017px 2px rgba(8, 21, 71, 0.65);
          opacity: 0.9;
        }
        .number {
          position: absolute;
          bottom: 37.5px;
          font-weight: bold;
          font-size: 27px;
          text-shadow: 0.58778524px 0.809017px 2px rgba(8, 21, 71, 0.65);
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 0;
          img {
            height: 54px;
            width: 54px;
          }
        }
        .free {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 5px;
          font-size: 17px;
          font-weight: bold;
          .icon {
            width: 29px;
            height: 29px;
          }
          .text {
            text-shadow: 0.58778524px 0.809017px 2px rgba(8, 21, 71, 0.65);
          }
          .el-icon-close {
            font-size: 18px;
            margin: 0 2px;
          }
          .right-text {
            padding: 0 5px;
            background-color: black;
            color: white;
            font-size: 12px;
            border-radius: 2px;
            flex-shrink: 0;
            margin-left: 8px;
          }
        }
      }
    }
  }
  &.is-mobile {
    .recharge-widget__content {
      width: 100%;
      &__list {
        padding: 0 12px;
        grid-template-columns: repeat(var(--recharge-mobile-column), 1fr);
        gap: 12px;
      }
      .item {
        height: 160px;
        .price {
          font-size: 16px;
          top: 0;
        }
        .number {
          font-size: 14px;
          bottom: 26px;
          font-weight: bold;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .title {
          font-size: 12px;
        }
        .free {
          transform: scale(0.8);
          bottom: 1px;
          font-size: 12px;
          .icon {
            width: 21px;
            height: 21px;
          }
        }
      }
    }
  }
}
</style>
