<template>
  <bg-style :bg="model.goodsBackground" class="store-widget-v2__one-column-list-item" @click.native="handleClick">
    <im-image v-if="isHasCorner" :src="good.corner" class="corner-logo" />
    <bg-style class="thumb relative" :bg="{...good.thumbBg, backgroundSize: model.iconFillType}" />
    <div class="detail-box relative">
      <div class="header flex items-center justify-between">
        <span class="name">{{ good.names.name }}</span>
        <i v-if="stopCountdownTimer" class="el-icon-time" />
      </div>
      <div class="desc">{{ good.names.desc }}</div>
      <div class="flex justify-between handle items-center">
        <span v-if="good.is_purchase_limit" class="limit">Limit {{ good.has_purchase_num }}/{{ good.limit_purchase_num }}</span>
        <div v-if="isShowCountdown" class="pre-btn flex items-center justify-center relative">
          <i class="el-icon-time text-14 mr-2" />
          <span>{{ countdownDays }}d:{{ countdownHours }}h:{{ countdownMinutes }}m:{{ countdownSeconds }}s</span>
        </div>
        <div v-else class="pay-btn">
          <shop-cart v-if="hasShopCart" class="shop-cart" :model="model" :good="good" :disabled="editing" />
          <div class="price-block" :class="{ 'no-shop-cart': !hasShopCart }" @click.stop="handleSubmit">
            {{ realPriceText }}
          </div>
        </div>
      </div>
    </div>
  </bg-style>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import ShopCart from '../shopCart.vue'
import goodMixins from "~/site/widgets/storeWidget/oneGood/goodMixins";

export default {
  name: 'StoreWidgetV2OneColumnListItem',
  components: {
    ShopCart
  },
  mixins: [goodMixins],
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    site: {
      type: Object,
      default() {
        return {}
      }
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  created() {
    //
  },
  methods: {
    handleClick() {
      if (this.editing) return
      this.$emit('click', this.good)
    },
    handleSubmit() {
      this.$emit('submit', cloneDeep(this.good))
    }
  }
}
</script>

<style lang="less">
.store-widget-v2__one-column-list-item {
  display: flex;
  margin-bottom: 14px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: var(--goods-border-radius);
  .color-bg {
    border-radius: var(--goods-border-radius);
  }
  .corner-logo {
    position: absolute;
    left: -3px;
    top: -3px;
    width: 62px;
    height: 62px;
    z-index: 10;
  }
  .thumb {
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    border-radius: var(--goods-border-radius) 0 0 var(--goods-border-radius);
    overflow: hidden;
  }
  .detail-box {
    padding: 10px 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .header {
      .name {
        font-size: 14px;
        color: var(--main-text-color);
        font-weight: bold;
      }
      i {
        color: var(--second-text-color);
      }
    }
    .desc {
      margin-top: 4px;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-size: 12px;
      overflow: hidden;
      color: var(--second-text-color);
    }
    .handle {
      margin-top: 8px;
      .limit {
        font-size: 10px;
        color: var(--second-text-color);
        flex-shrink: 0;
        margin-right: 20px;
        border: 1px solid var(--second-text-color);
        padding: 0 4px;
        border-radius: var(--goods-border-radius);
      }
      .pre-btn {
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        color: white;
        font-size: 12px;
        width: 100%;
        border-radius: var(--goods-border-radius);
      }
      .pay-btn {
        display: flex;
        width: 100%;
        .shop-cart {
          width: 32px;
          height: 32px;
          background: var(--button-bg-color);
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--button-text-color);
          font-size: 18px;
          border-radius: var(--goods-border-radius) 0 0 var(--goods-border-radius);
          flex-shrink: 0;
        }
        .price-block {
          background: var(--button-bg-color);
          width: 100%;
          line-height: 32px;
          text-align: center;
          border-radius: 0 var(--goods-border-radius) var(--goods-border-radius) 0;
          color: var(--button-text-color);
          font-size: 12px;
          margin-left: 5px;
          &.no-shop-cart {
            border-radius: var(--goods-border-radius);
          }
        }
      }
    }
  }
}
</style>
