<template>
  <div class="point-store-edit">
    <menu-item label="背景设置">
      <menu-fill :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="标题">
      <menu-switch v-model="model.titleVisible" label="显示" />
    </menu-item>
    <menu-item label="移动端商品布局">
      <flex-select v-model="model.mobileLayoutType" :options="[StoreWidgetV2FlexLayoutEnum.TWO_COLUMN]" />
    </menu-item>
    <menu-item label="商品背景色">
      <menu-fill :target="model.goodsBackground" target-key="goodsBackground" />
    </menu-item>
    <menu-item label="圆角">
      <menu-slider v-model="model.radius" target-key="radius" />
    </menu-item>
    <menu-item label="商品ICON填充样式">
      <im-select v-model="model.iconFillType" class="w-full" size="small" :options="bgSizeList" />
    </menu-item>
    <menu-item label="按钮填充色">
      <menu-fill :target="model.buttonBackground" target-key="buttonBackground" />
    </menu-item>
    <menu-item label="按钮文字色">
      <menu-fill :target="model.buttonColor" solid-color target-key="buttonColor" />
    </menu-item>
    <menu-item label="主要文字颜色">
      <menu-fill :target="model.mainTextColor" solid-color target-key="mainTextColor" />
    </menu-item>
    <menu-item label="次要文字颜色">
      <menu-fill :target="model.secondaryTextColor" solid-color target-key="secondaryTextColor" />
    </menu-item>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue";
import MenuItem from "~/components/menu/menuItem.vue";
import MenuSwitch from "~/components/menu/menuSwitch.vue";
import MenuSlider from "~/components/menu/menuSlider.vue";
import MenuSelect from "~/components/menu/menuSelect.vue";
import MenuFill from "~/components/menu/menuFill.vue";
import ImSelect from "~/components/common/ImSelect.vue";
import {BackgroundSizeList} from "~/enums/DomEnum";
import FlexSelect from "~/components/flexSelect/index.vue";
import {StoreWidgetV2FlexLayoutEnum} from "~/enums/layoutEnum";
import RemoveBtn from "~/components/common/RemoveBtn.vue";

export default {
  name: 'VipPointsStoreWidgetEdit',
  components: {
    RemoveBtn,
    FlexSelect, ImSelect, MenuFill, MenuSelect, MenuSlider, MenuSwitch, MenuItem, MenuBackgroundImage},
  props: {
    model: {
      type: Object,
      default() {
        return Object.freeze({})
      }
    }
  },
  data() {
    return {
      bgSizeList: BackgroundSizeList.map((item) => {
        return {
          label: this.$t('edit.' + item),
          value: item
        }
      })
    }
  },
  computed: {
    StoreWidgetV2FlexLayoutEnum() {
      return StoreWidgetV2FlexLayoutEnum
    }
  },
  methods: {

  }
}
</script>

<style lang="less">

</style>
