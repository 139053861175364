<template>
  <bg-style :bg="model.goodsBackground" class="point-store__two-column-item relative">
    <div class="thumb">
      <bg-style class="relative h-full" :bg="{...item.thumbBg, backgroundSize: model.iconFillType}" />
    </div>
    <div class="info relative">
      <div class="name text-left">{{ item.name }}</div>
      <div v-if="stopCountdownTimer" class="off-sale">
        {{ $t('siteBuild.stopCountdown') }}：<span>{{ stopCountdownDays }}d:{{ stopCountdownHours }}:{{ stopCountdownMinutes }}:{{ stopCountdownSeconds }}</span>
      </div>
      <div v-if="item.is_purchase_limit" class="limit">
        Limit {{ item.has_purchase_num }}/{{ item.limits }}
      </div>
    </div>
    <div v-if="isShowCountdown" class="px-[10px] my-[10px]">
      <div class="pre-btn relative">
        <div class="flex items-center">
          <i class="el-icon-time text-14 mr-1" />
          <template v-if="countdownDays === '00' && countdownHours !== '00'">
            <span>{{ countdownHours }}h:{{ countdownMinutes }}m</span>
          </template>
          <template v-if="countdownDays !== '00'">
            <span>{{ countdownDays }}d:{{ countdownHours }}h</span>
          </template>
          <template v-if="countdownDays === '00' && countdownHours === '00'">
            <span>{{ countdownMinutes }}m:{{ countdownSeconds }}s</span>
          </template>
        </div>
      </div>
    </div>
    <div v-else class="pay-btn relative" @click="handleBuy(item)">
      <div class="price-block flex items-center justify-center">
        <img class="w-[26px] h-[26px] mr-2" :src="$store.state.project.vipConfig?.point_icon" alt="" />
        <span>{{ item.price }}</span>
      </div>
    </div>
  </bg-style>
</template>

<script>
import pointGoods from "~/mixins/pointGoods";

export default {
  name: 'VipPointTwoColumnListItem',
  mixins: [pointGoods],
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style lang="less">
.point-store__two-column-item {
  height: fit-content;
  overflow: hidden;
  border-radius: var(--goods-border-radius);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  .thumb {
    height: 122px;
    .color-bg {
      border-radius: var(--goods-border-radius) var(--goods-border-radius);
    }
  }
  .info {
    .name {
      font-size: 12px;
      font-weight: bold;
      margin-top: 8px;
      padding: 0 10px;
      color: var(--main-text-color);
    }
    .limit {
      font-size: 10px;
      margin: 10px 12px 10px;
      color: var(--main-text-color);
      border: 1px solid var(--main-text-color);
      padding: 2px 4px;
      width: fit-content;
      border-radius: var(--goods-border-radius);
    }
    .off-sale {
      font-size: 10px;
      color: var(--second-text-color);
      padding: 0 10px;
      margin-top: 8px;
    }
  }
  .pay-btn {
    padding: 0 10px;
    margin: 10px 0;
    .price-block {
      background: var(--button-bg-color);
      width: 100%;
      line-height: 32px;
      text-align: center;
      border-radius: var(--goods-border-radius);
      color: var(--button-text-color);
      font-size: 12px;
    }
  }
  .pre-btn {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--goods-border-radius);
    color: var(--button-text-color);
    font-size: 12px;
  }
}
</style>
