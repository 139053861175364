<template>
  <div class="store-widget-v2__edit">
    <menu-item label="背景设置">
      <menu-fill :target="model.background" target-key="background" />
    </menu-item>
    <menu-item label="显隐设置">
      <menu-switch v-model="model.titleVisible" target-key="titleVisible" label="标题" />
      <menu-switch v-model="model.classifyVisible" target-key="classifyVisible" label="商品分类名称" />
    </menu-item>
    <menu-item label="移动端商品布局">
      <flex-select v-model="model.mobileLayoutType" />
    </menu-item>
    <menu-item label="商品背景">
      <menu-fill :target="model.goodsBackground" target-key="goodsBackground" />
    </menu-item>
    <menu-item label="商品圆角">
      <menu-slider v-model="model.goodsRadius" target-key="goodsRadius" />
    </menu-item>
    <menu-item label="商品ICON填充类型">
      <im-select v-model="model.iconFillType" class="w-full" size="small" :options="bgSizeList" />
    </menu-item>
    <menu-item label="按钮填充色">
      <menu-fill :target="model.buttonBackground" target-key="buttonBackground" />
    </menu-item>
    <menu-item label="按钮文字颜色">
      <menu-fill :target="model.buttonTextColor" solid-color target-key="buttonTextColor" />
    </menu-item>
    <menu-item label="主要文字颜色">
      <menu-fill :target="model.mainTextColor" solid-color target-key="mainTextColor" />
    </menu-item>
    <menu-item label="次要文字颜色">
      <menu-fill :target="model.secondaryTextColor" solid-color target-key="secondaryTextColor" />
    </menu-item>
    <menu-item label="商店板块">
      <div v-for="(item, index) in model.classifyList" :key="index" class="bg-fillColor-2 p-2 mb-2 text-12">
        <div class="flex justify-between items-center">
          <span>商店板块{{ index + 1 }}</span>
          <i class="el-icon-delete cursor-pointer hover:text-danger" @click="model.classifyList.splice(index, 1)" />
        </div>
        <im-select v-model="item.value" class="w-full mt-2" :options="$store.state.goods.groupList" size="small" />
      </div>
    </menu-item>
    <menu-button class="mt-4" icon="add" type="ghost-copy" @click.native="handleAddClassify">添加商店板块</menu-button>
    <menu-button class="mt-4" icon="add" :help-text="$t('menu.copyBlock')" @click.native.stop="handleClone">{{ $t('menu.createCopy') }}</menu-button>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import MenuFill from "~/components/menu/menuFill.vue";
import MenuItem from "~/components/menu/menuItem.vue";
import MenuSwitch from "~/components/menu/menuSwitch.vue";
import FlexSelect from "~/components/flexSelect/index.vue";
import MenuSlider from "~/components/menu/menuSlider.vue";
import ImSelect from "~/components/common/ImSelect.vue";
import {BackgroundSizeList} from "~/enums/DomEnum";
import MenuButton from "~/components/menu/menuButton.vue";
import RemoveBtn from "~/components/common/RemoveBtn.vue";

export default {
  name: 'StoreWidgetV2Edit',
  components: {RemoveBtn, MenuButton, ImSelect, MenuSlider, FlexSelect, MenuSwitch, MenuItem, MenuFill},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      bgSizeList: BackgroundSizeList.map((item) => {
        return {
          label: this.$t('edit.' + item),
          value: item
        }
      })
    }
  },
  methods: {
    handleAddClassify() {
      this.model.classifyList.push({ value: '' })
    }
  }
}
</script>

<style lang="less">

</style>
