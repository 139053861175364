<template>
<div class="recharge-v2-edit">
  <menu-item label="背景设置">
    <menu-fill :target="model.background" target-key="background" />
    <menu-background-image class="mt-2" :target="model.background" target-key="background" />
  </menu-item>
  <menu-item label="布局">
    <menu-switch v-model="model.titleVisible" label="标题" target-key="titleVisible" />
    <menu-switch v-model="model.descVisible" label="描述" target-key="descVisible" />
<!--    <menu-switch v-model="model.anyVisible" label="任意面额" target-key="anyVisible">-->
<!--      <menu-background-image :target="model.anyIconBackground" target-key="anyIconBackground" class="w-full mt-2 flex-shrink-0" />-->
<!--    </menu-switch>-->
  </menu-item>
  <menu-item label="卡片背景" size-tip="(4:3)">
    <menu-fill :target="model.cardBackground" target-key="cardBackground" />
    <menu-background-image class="mt-2" :target="model.cardBackground" target-key="cardBackground" />
  </menu-item>
  <menu-item label="卡片圆角">
    <menu-slider v-model="model.cardRadius" target-key="cardRadius" />
  </menu-item>
  <menu-item label="面额文字颜色">
    <menu-fill :target="model.textColor" solid-color target-key="textColor" />
  </menu-item>
  <menu-item label="按钮填充颜色">
    <menu-fill :target="model.buttonBackground" target-key="buttonBackground" />
  </menu-item>
  <menu-item label="按钮文字颜色">
    <menu-fill :target="model.buttonColor" solid-color target-key="buttonColor" />
  </menu-item>
<!--  <menu-item label="充值ICON">-->
<!--    <menu-background-image :target="model.iconBackground" target-key="iconBackground" />-->
<!--  </menu-item>-->
  <remove-btn class="mt-4" />
</div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem.vue"
import MenuFill from "~/components/menu/menuFill.vue"
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage.vue"
import MenuSwitch from "~/components/menu/menuSwitch.vue"
import MenuSlider from "~/components/menu/menuSlider.vue"
import RemoveBtn from "~/components/common/RemoveBtn.vue"

export default {
  name: 'RechargeWidgetV2Edit',
  components: {RemoveBtn, MenuSlider, MenuSwitch, MenuBackgroundImage, MenuFill, MenuItem},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {
    //
  }
}
</script>

<style lang="less">

</style>
